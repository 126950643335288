import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import request from '@/utils/request';
import { viewOfficeUrl } from '@/utils';
export default {
  queryShareId: function queryShareId(shareId) {
    return request.get("/app-doc-center/v1/doc/queryShareById?shareId=".concat(shareId)).then(function (res) {
      return res;
    });
  },
  pubArchiveDoc: function pubArchiveDoc(parasm) {
    return request.post('/app-doc-center/v1/implementVersionRelease', parasm).then(function (res) {
      return res;
    });
  },
  PLMPublish: function PLMPublish(data) {
    return request.post('/app/v1/getCadForder', data).then(function (res) {
      return res;
    });
  },
  PDMPublish: function PDMPublish(data) {
    return request.post('/app-doc-center/v1/pdm/sendToPdmFileInfo', data).then(function (res) {
      return res;
    });
  },
  PLMPublished: function PLMPublished(data) {
    return request.post('/app-doc-center/v1/drawingRelease', data).then(function (res) {
      return res;
    });
  },
  queryTiziPreviewUrl: function queryTiziPreviewUrl(fileId) {
    return request.post("file/v1/api/file/previewFile?fileId=".concat(fileId, "&type=oss")).then(function (res) {
      return res;
    });
  },
  tiziDwgStartQinglianghua: function tiziDwgStartQinglianghua(fileId) {
    return request.get("/app-doc-center/v1/hardcover-file/kit-dwg-convert?fileId=".concat(fileId)).then(function (res) {
      return res;
    });
  },
  queryFileFolderStatus: function queryFileFolderStatus(data) {
    return request.post('/app-doc-center/v1/query-merge-status', data).then(function (res) {
      return res;
    });
  },
  queryTree: function queryTree() {
    var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return request.post("/app-doc-center/v1/doc/queryList?r=".concat(Date.now()), data).then(function (res) {
      return res;
    });
  },
  queryMyChat: function queryMyChat(pageNo, pageSize) {
    return request.get("/app-doc-center/v1/doc/queryMyChat?pageNo=".concat(pageNo, "&pageSize=").concat(pageSize));
  },
  newQueryTree: function newQueryTree() {
    var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return request.post('/app-doc-center/v1/doc/getFolderOrFile', data).then(function (res) {
      return res;
    });
  },
  queryHyTree: function queryHyTree(prjId, folderId, type) {
    return request.get("/app-doc-center/v1/ecm/getFolderList?prjId=".concat(prjId, "&folderId=").concat(folderId, "&type=").concat(type)).then(function (res) {
      return res;
    });
  },
  queryRecycle: function queryRecycle(prjID) {
    return request.get("/app-doc-center/v1/doc/queryDelList?prjID=".concat(prjID)).then(function (res) {
      return res;
    });
  },
  queryAddFolder: function queryAddFolder() {
    var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return request.post('/app-doc-center/v1/doc/add', data).then(function (res) {
      return res;
    });
  },
  queryDeleteFile: function queryDeleteFile(folderID) {
    return request.post("/app-doc-center/v1/doc/delete?ids=" + folderID).then(function (res) {
      return res;
    });
  },
  //供方仿真模拟提资资料文件下载
  queryfangzhenmoniFileDownload: function queryfangzhenmoniFileDownload(fileId, breakPointType) {
    return request.get("/app-doc-center/v1/digitalLibrary/getDownloadUrl?fileId=".concat(fileId, "&breakPointType=").concat(breakPointType)).then(function (res) {
      return res;
    });
  },
  delFangZhenFile: function delFangZhenFile(fileId) {
    return request.get("/app-doc-center/v1/digitalLibrary/deleteResourceByFileId?fileId=".concat(fileId)).then(function (res) {
      return res;
    });
  },
  queryRechristen: function queryRechristen() {
    var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return request.post('/app-doc-center/v1/doc/update', data).then(function (res) {
      return res;
    });
  },
  querySearchFolder: function querySearchFolder() {
    var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return request.post('/app-doc-center/v1/doc/query', data).then(function (res) {
      return res;
    });
  },
  queryCopy: function queryCopy(folderID, parentID, prjID) {
    return request.post("/app-doc-center/v1/doc/copy?ids=".concat(folderID, "&parentID=").concat(parentID, "&prjID=").concat(prjID)).then(function (res) {
      return res;
    });
  },
  queryHyCopy: function queryHyCopy() {
    var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return request.post('/app-doc-center/v1/ecm/copyEcmFolder', data).then(function (res) {
      return res;
    });
  },
  queryMove: function queryMove(folderID, parentID) {
    return request.post("/app-doc-center/v1/doc/move?ids=".concat(folderID, "&parentID=").concat(parentID)).then(function (res) {
      return res;
    });
  },
  queryBaseProperty: function queryBaseProperty(folderID, isFolder, versionID) {
    return request.post("/app-doc-center/v1/doc/queryDetail?id=".concat(folderID, "&isFolder=").concat(isFolder, "&versionID=").concat(versionID)).then(function (res) {
      return res;
    });
  },
  queryLinkDoc: function queryLinkDoc(fileID) {
    return request.post("/app-doc-center/v1/doc/relevancy/query", fileID).then(function (res) {
      return res;
    });
  },
  addLinkDoc: function addLinkDoc() {
    var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return request.post("/app-doc-center/v1/doc/relevancy/add", data).then(function (res) {
      return res;
    });
  },
  deleteLinkDoc: function deleteLinkDoc(relaID) {
    return request.post("/app-doc-center/v1/doc/relevancy/delete?relaID=".concat(relaID)).then(function (res) {
      return res;
    });
  },
  queryFolderAuth: function queryFolderAuth(folderID, userType) {
    return request.post("/app-doc-center/v1/doc/privi-query?folderID=".concat(folderID, "&userType=").concat(userType)).then(function (res) {
      return res;
    });
  },
  queryPerson: function queryPerson(prjId) {
    return request.get("/app/v1/iamUserInfo/getUserInfoByPrjId?prjId=".concat(prjId)).then(function (res) {
      return res;
    });
  },
  queryPersonNew: function queryPersonNew(prjId) {
    return request.get("/app/v1/iamUserInfo/getPrjUserInfoList?prjId=".concat(prjId)).then(function (res) {
      return res;
    });
  },
  getFileSendMessage: function getFileSendMessage(params) {
    return request.post("/app-doc-center/v1/doc/getFileSendMessage", params).then(function (res) {
      return res;
    });
  },
  queryRole: function queryRole(prjId) {
    return request.get("/app/v1/iamUserInfo/getRoleNameByPrjId?prjId=".concat(prjId)).then(function (res) {
      return res;
    });
  },
  addOrDelOrUpPersonOrRole: function addOrDelOrUpPersonOrRole(list) {
    return request.post("/app-doc-center/v1/doc/privi-operation", list).then(function (res) {
      return res;
    });
  },
  creatVersion: function creatVersion(versionObj) {
    return request.post("/app-doc-center/v1/doc/edition", versionObj).then(function (res) {
      return res;
    });
  },
  creatProVersion: function creatProVersion(params) {
    return request.post("/app-doc-center/v1/doc/addStage", params);
  },
  updateVersionName: function updateVersionName(data) {
    return request.post("/app-doc-center/v1/doc/edition/update", data).then(function (res) {
      return res;
    });
  },
  interiorShare: function interiorShare(data) {
    return request.post("/app-doc-center/v1/doc/internal-share", data).then(function (res) {
      return res;
    });
  },
  creatShareLink: function creatShareLink(data) {
    return request.post("/app-doc-center/v1/doc/genShareLink", data).then(function (res) {
      return res;
    });
  },
  queryVersions: function queryVersions(folderID, prjID) {
    return request.get("/app-doc-center/v1/doc/edition/query?folderID=".concat(folderID, "&prjID=").concat(prjID)).then(function (res) {
      return res;
    });
  },
  confirmVersion: function confirmVersion(versionID) {
    return request.get("/app-doc-center/v1/doc/edition/define?versionID=".concat(versionID)).then(function (res) {
      return res;
    });
  },
  deleteVersion: function deleteVersion(versionID) {
    return request.post("/app-doc-center/v1/doc/edition/delete?versionID=".concat(versionID)).then(function (res) {
      return res;
    });
  },
  getoffice: function getoffice(fileId, prjId) {
    var url = viewOfficeUrl(fileId, prjId);
    return request.get(url).then(function (res) {
      return res;
    });
  },
  getofficeGml: function getofficeGml(fileId, type) {
    return request.post("/app-doc-center/v1/doc/getFilePreviewUrl-gml?fileId=" + fileId + "&type=" + type).then(function (res) {
      return res;
    });
  },
  getofficeGmlNEW: function getofficeGmlNEW(fileId) {
    return request.post("/app-doc-center/v1/doc/getFilePreviewUrlNew-gml?fileId=" + fileId).then(function (res) {
      return res;
    });
  },
  queryLocalOfficeUrl: function queryLocalOfficeUrl(fileId, prjId) {
    return request.get("/app-doc-center/v1/wopi/preview/office?fileId=".concat(fileId, "&prjId=").concat(prjId)).then(function (res) {
      return res;
    });
  },
  getRreadArr: function getRreadArr(data) {
    return request.post("/app-doc-center/v1/doc/navbar", data).then(function (res) {
      return res;
    });
  },
  fileMerge: function fileMerge(data) {
    return request.post("/app-doc-center/v1/mergeFile", data).then(function (res) {
      return res;
    });
  },
  archive: function archive(data) {
    return request.post("/app-doc-center/v1/doc/archive", data).then(function (res) {
      return res;
    });
  },
  //浏览记录接口
  saveScanHistory: function saveScanHistory(obj) {
    return request.post("/app-doc-center/v1/doc/file-oper/save", obj).then(function (res) {
      return res;
    });
  },
  addTemplate: function addTemplate(data) {
    return request.post("/app-doc-center/v1/doc/add-folder-template", data).then(function (res) {
      return res;
    });
  },
  queryModelLinkDoc: function queryModelLinkDoc(data) {
    return request.post("/app-doc-center/v1/doc/model/relevancy/query", data).then(function (res) {
      return res;
    });
  },
  deleteModelLinkDoc: function deleteModelLinkDoc(relaID) {
    return request.post("/app-doc-center/v1/doc/model/relevancy/delete?relaID=".concat(relaID)).then(function (res) {
      return res;
    });
  },
  addModelLinkDoc: function addModelLinkDoc(data) {
    return request.post("/app-doc-center/v1/doc/model/relevancy/add", data).then(function (res) {
      return res;
    });
  },
  isArchiveFile: function isArchiveFile(fileId) {
    return request.get("/app-doc-center/v1/doc/archive/get-file-real/".concat(fileId)).then(function (res) {
      return res;
    });
  },
  //模型关联文件预览,根据fileid,prjid查询最新的versionId,versionName
  queryNewVersionIdAndVersionName: function queryNewVersionIdAndVersionName(prjId, fileId) {
    return request.get("/app-doc-center/v1/doc/get-docVersion-byFileId?prjId=".concat(prjId, "&fileId=").concat(fileId)).then(function (res) {
      return res;
    });
  },
  //调用接口判断当前用户是否有归档/创建版本/确认版本的权限
  queryIsArchiveVersionAuth: function queryIsArchiveVersionAuth(prjId, folderId) {
    return request.get("/app-doc-center/v1/doc/get-docPermission?prjId=".concat(prjId, "&folderId=").concat(folderId)).then(function (res) {
      return res;
    });
  },
  recycleDeleteAndRestore: function recycleDeleteAndRestore() {
    var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return request.post('/app-doc-center/v1/doc/recycle/update', data).then(function (res) {
      return res;
    });
  },
  queryCADFolderID: function queryCADFolderID(prjID, folderId) {
    return request.get("/app-doc-center/v1/doc/query-id-by-oldid?prjID=".concat(prjID, "&folderId=").concat(folderId)).then(function (res) {
      return res;
    });
  },
  docCreatLFCFile: function docCreatLFCFile() {
    var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return request.post('/app/v1/generateLfcFile', data).then(function (res) {
      return res;
    });
  },
  rvtFileProperty: function rvtFileProperty(fileId) {
    return request.post("/app/v1/updateFileAttribute?fileId=".concat(fileId)).then(function (res) {
      return res;
    });
  },
  updateLight: function updateLight(fileId, type) {
    return request.get("/app-doc-center/v1/doc/re-convert/".concat(fileId, "/").concat(type)).then(function (res) {
      return res;
    });
  },
  queryFileIsSubmit: function queryFileIsSubmit(data) {
    return request.post('/app-doc-center/v1/doc/getVersionLock', data).then(function (res) {
      return res;
    });
  },
  queryToken: function queryToken() {
    return request.get("/app/v1/query/token").then(function (res) {
      return res;
    });
  },
  queryMyShareToken: function queryMyShareToken(shareID) {
    return request.post("/app-doc-center/v1/doc/createShareToken?shareID=".concat(shareID)).then(function (res) {
      return res;
    });
  },
  addOperationLog: function addOperationLog() {
    var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return request.post('/app-doc-center/v1/doc/add-folderOperHis', data);
  },
  queryIsInitFolder: function queryIsInitFolder(prjID, folderId) {
    return request.get("/app-doc-center/v1/doc/query-id-by-oldid?prjID=".concat(prjID, "&folderId=").concat(folderId));
  },

  /**
   * 
   * @param {项目ID} prjId 
   * @returns 根目录ID
   */
  queryRootFolderId: function queryRootFolderId(prjId) {
    return request.get("/app-doc-center/v1/doc/query-id-by-oldid-gml?prjID=".concat(prjId));
  },
  queryQuestionNumber: function queryQuestionNumber(data) {
    return request.post("halo/api/app-doc-center/v1/problem/manage/query-file-question-number", data);
  },
  queryOfficeStatus: function queryOfficeStatus(folderID) {
    var docVersionId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
    return request.get("halo/api/app-doc-center/v1/doc/queryFileDetail?id=".concat(folderID, "&docVersionId=").concat(docVersionId));
  },
  queryCopyMoveStatus: function queryCopyMoveStatus(taskId) {
    return request.get("halo/api/app-doc-center/v1/doc/query-task-status?taskId=".concat(taskId, "&r=").concat(Date.now()));
  },
  addFavorite: function addFavorite(data) {
    return request.post("/app-doc-center/v1/doc/addFavorite", data);
  },
  deleteFavorite: function deleteFavorite(data) {
    return request.post("/app-doc-center/v1/doc/deleteFavorite", data);
  },
  queryFavorite: function queryFavorite(pageNo, pageSize) {
    return request.get("/app-doc-center/v1/doc/queryFavorite?pageNo=".concat(pageNo, "&pageSize=").concat(pageSize));
  },
  queryCollaborativeDocUrl: function queryCollaborativeDocUrl(prjID) {
    return request.get("/app-doc-center/v1/doc/getShimoFolderUrl?spaceId=".concat(prjID));
  },
  queryShiMoDocUrl: function queryShiMoDocUrl(prjID) {
    return request.get("/v1/api/common/getShimoFolderUrl?spaceId=".concat(prjID));
  },
  getFolderPrivi: function getFolderPrivi(data) {
    return request.post('/app-doc-center/v1/doc/getFolderPrivi', data);
  },
  getShareQueryList: function getShareQueryList(data) {
    return request.post('/app-doc-center/v1/doc/share/queryList', data);
  },
  transformUE4: function transformUE4(data) {
    return request.post('/digitalUe4/v1/api/transform/launch', data).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  //同步人员
  SynchronousUser: function SynchronousUser(prjID) {
    return request.get("/app/v1/personPlan/refreshUser?prjId=".concat(prjID));
  },
  qlhfileId: function qlhfileId(fileId) {
    return request.get("/app-doc-center/v1/doc/re-convert/".concat(fileId, "/0")).then(function (res) {
      return res;
    });
  }
};